/* Posiciona el botón en la esquina superior derecha */
.theme-switch-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline: none;
  transition: transform 0.3s ease-out, background-color 0.3s ease-out;
}

/* Contenedor interno con tamaño reducido y bordes redondeados */
.theme-switch {
  display: inline-flex;
  width: 40px;  /* Tamaño reducido para mayor sutileza */
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  transition: background-color 0.4s ease-out;
}

/* Slider que alberga el SVG y sus transiciones */
.slider {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Animación de "dopamine burst" al hacer clic */
.theme-switch-container.dopamine {
  animation: dopamineBurst 0.5s ease-out;
}

@keyframes dopamineBurst {
  0% { transform: scale(1); }
  50% { transform: scale(1.15); }
  100% { transform: scale(1); }
}

/* Ajusta el SVG al contenedor de forma sutil */
.slider svg {
  width: 80%;
  height: 80%;
  transition: all 0.3s ease-out;
}
