/* Contenedor principal */
.transactions-container {
  padding: 1rem;
  font-family: 'Arial', sans-serif;
}

.transactions-container.dark {
  color: #eee;
  background-color: #222;
}

.transactions-container:not(.dark) {
  color: #333;
  background-color: #fff;
}

/* Título */
.transactions-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

/* Botón de acción */
.toggle-button {
  display: inline-block;
  margin-bottom: 1rem;
  padding: 0.75rem 1.5rem;
  background: #0070f3;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

.toggle-button:hover {
  background: #005bb5;
}

/* Lista de transacciones */
.transactions-list {
  list-style: none;
  padding: 0;
}

/* Ítem individual de transacción */
.transaction-item {
  padding: 0.75rem 1rem;
  background: #f9f9f9;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  transition: background 0.3s ease;
}

.transactions-container.dark .transaction-item {
  background: #3a3a3a;
}

/* Resumen de la transacción (acordeón) */
.transaction-summary {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

/* Estilos para cada campo del resumen */
.transaction-summary span {
  flex-shrink: 0;
}

/* Campo de referencia: se aplica truncamiento si es muy largo */
.transaction-reference {
  flex: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Fecha y estado centrados */
.transaction-date,
.transaction-status {
  flex: 1;
  text-align: center;
}

/* Icono de expansión */
.transaction-icon {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.transaction-icon.expanded {
  transform: rotate(90deg);
}

/* Detalles de la transacción (acordeón) */
.transaction-details {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.transaction-details.open {
  max-height: 500px; /* Ajustable según necesidad */
  opacity: 1;
}

/* Contenido interno de los detalles */
.details-content {
  padding: 0.5rem 1rem;
  border-top: 1px solid #ccc;
  margin-top: 0.5rem;
}
