/* Scrollbar personalizada para navegadores basados en WebKit */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.3);
  border-radius: 4px;
  transition: background-color 0.3s;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.5);
}

/* Scrollbar para tema oscuro */
.dark .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(200, 200, 200, 0.3);
}

.dark .custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(200, 200, 200, 0.5);
}

/* Scrollbar para tema claro */
.light .custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.3);
}

.light .custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.5);
}

/* Para navegadores que soportan scrollbar-width (Firefox) */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(100, 100, 100, 0.3) transparent;
}

.dark .custom-scrollbar {
  scrollbar-color: rgba(200, 200, 200, 0.3) transparent;
}

.light .custom-scrollbar {
  scrollbar-color: rgba(100, 100, 100, 0.3) transparent;
}
