/* src/index.css */

/* Directivas de Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Estilos personalizados */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilos para Android (usando media queries basadas en el tamaño de pantalla como referencia) */
@media only screen and (max-width: 768px) and (-webkit-device-pixel-ratio: 3) {
  /* Aquí puedes agregar estilos específicos para dispositivos Android con pantallas de alta densidad */
  body {
    background-color: #f0f0f0;
    font-size: 16px;
  }
}

/* Estilos para iOS */
@media only screen and (max-width: 768px) and (-webkit-device-pixel-ratio: 2) {
  /* Estilos para dispositivos iOS como iPhone */
  body {
    background-color: #fff5f5;
    font-size: 15px;
  }
}

/* Estilos para plataformas de escritorio */
@media only screen and (min-width: 1024px) {
  body {
    background-color: #e0e0e0;
    font-size: 18px;
  }
}

/* Estilos personalizados para el scrollbar (opcional si usas clases de Tailwind) */
/* Puedes omitir esto si usas solo clases de Tailwind */
